<template>
  <v-container>

    <v-row v-if="isMobile">
      <v-col>
        <Spinner v-if="loading"/>
        <v-sheet
          v-else
          v-for="(event, i) in timeline"
          :key="i"
          class="mb-3"
        >
          <v-card class="text-left">
            <v-card-title class="text-break" :class="`headline font-weight-light mb-4 ${event.color}--text`">
              {{event.title}}
            </v-card-title>
            <v-card-subtitle class="text-justify">
              <b>{{event.date}}</b>
              <br />
              <span v-html="event.desc"></span>
            </v-card-subtitle>
            <v-row>
              <v-col v-for="(element, index) in event.images" :key="index" cols="4" sm="4" md="4" xs="4">
                <v-dialog
                  transition="dialog-top-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card>
                      <v-img
                        :src="element.link"
                        :alt="element.title.rendered"
                        aspect-ratio="1"
                        class="grey lighten-2"
                        v-bind="attrs"
                        v-on="on"
                        @click="selectImage(element, index)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-img
                        :src="selectedElement.link"
                        :lazy-src="selectedElement.link"
                        :alt="selectedElement.title.rendered"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-container class="d-flex align-center">
                        <v-col>
                          <v-row v-if="element.description" class="d-flex justify-start">
                            {{ element.title.rendered }}
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row class="d-flex justify-end align-center">
                            <v-btn
                              @click="dialog.value = false"
                            >
                            {{translation.close}}
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-container>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <Spinner v-if="loading"/>
        <v-timeline v-else>
          <v-timeline-item
              v-for="(event, i) in timeline"
              :key="i"
              :color="event.color"
              small
          >
            <template v-slot:opposite>
              <span
                  :class="`headline font-weight-bold ${event.color}--text`"
                  v-text="event.date"
                  v-show="showItems"
              ></span>
            </template>

            <v-card class="text-left" v-show="showItems">
              <v-card-title class="text-break" :class="`headline font-weight-light mb-4 ${event.color}--text`">
                {{event.title}}
              </v-card-title>
              <v-card-subtitle class="text-justify">
                <span v-html="event.desc"></span>
              </v-card-subtitle>
              <v-row>
                <v-col v-for="(element, index) in event.images" :key="index" cols="12" sm="6" md="4">
                  <v-dialog
                    transition="dialog-top-transition"
                    max-width="600"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-card>
                        <v-img
                          :src="element.link"
                          :alt="element.title.rendered"
                          aspect-ratio="1"
                          class="grey lighten-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectImage(element, index)"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-img
                          :src="selectedElement.link"
                          :lazy-src="selectedElement.link"
                          :alt="selectedElement.title.rendered"
                          aspect-ratio="1"
                          class="grey lighten-2"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-container class="d-flex align-center">
                          <v-col>
                            <v-row v-if="element.description" class="d-flex justify-start">
                              {{ element.title.rendered }}
                            </v-row>
                          </v-col>
                          <v-col>
                            <v-row class="d-flex justify-end align-center">
                              <v-btn
                                @click="dialog.value = false"
                              >
                              {{translation.close}}
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-container>
                      </v-card>
                    </template>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/config"
import Spinner from '@/components/Spinner.vue';
export default {

  components: {
    Spinner,
  },

  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
      }
    return false;
    }
  },

  data: () => ({
    loading: true,
    timeline: [],
    translation: {
      close: "Zamknij",
    },
    selectedElement: null,
    currentIndex: 0,
    showItems: false
  }),

  mounted() {
    this.getPosts();
  },
  methods: {
    async getPosts() {
      let that = this;
      this.loading = true;
      const url = config.API_URL + 'posts?_fields=title,content,date,_links';
      let currentPage = 1;
      const perPage = 100;
      let posts = [];
      let loop = true;
      while(loop) {
        let result = await this.fetchData(url + `&per_page=${perPage}&page=${currentPage}`);
        if(result === false)
          break;
        posts = posts.concat(result);
        currentPage++;
      }
      if(posts.length === 0)
        return false;
      const reversePosts = posts.reverse();
      this.createPosts(reversePosts)
      .then(result => {
        that.timeline = result;
        that.loading = false;
        that.$nextTick(() => {
          that.showItems = true;
        });
      })
      .catch(error => {
        console.error('Error:', error);
        that.loading = false;
        that.$nextTick(() => {
          that.showItems = true;
        });
      });
    },

    async createPosts(posts) {
        try {
            const promises = posts.map(async (element) => {
                const images = await this.fetchData(element._links['wp:attachment'][0].href);
                return {
                    color: "black",
                    date: new Date(element.date).toLocaleDateString('pl-PL') + ' r.',
                    title: element.title.rendered,
                    desc: element.content.rendered,
                    images: images
                };
            });

            const results = await Promise.all(promises);
            return results.reverse();
        } catch (error) {
            console.error('Error:', error);
            throw new Error("Cannot load images");
        }
    },

    async fetchData(url) {
      
      try {
        const response = await fetch(url);
        const result = await response.json();
        if(!Array.isArray(result) || result.length === 0)
          return false;
        return result;
      } catch (error) {
        console.error(error.message);
        return false;
      }
    },

    selectImage(element, index) {
      this.selectedElement = element;
      this.currentIndex = index;
    },
  }
}
</script>

<style scoped>
</style>