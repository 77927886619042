<template>
    <div class="spinner">
        <v-progress-circular
        indeterminate
        :color=color>
        </v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: 'Spinner',

        props: {
            color: {
                type: String,
                default: 'black'
            },
        }
    };
</script>

<style scoped>
    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }
</style>
  